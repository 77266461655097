import get from 'lodash/get';

export const hasFeatureWaffle = (feature, waffle = window.waffle, attr = 'flag_is_active') => waffle[attr](feature);

export const hasFeature = (instance, feature, defaultValue = false) => {
  let items = instance;

  if (!Array.isArray(instance)) {
    items = [instance];
  }
  return items.some(i => get(i, `configurations.features.${feature}.enabled`, defaultValue));
};

export const getFeature = (instance, feature) => get(instance, `configurations.features.${feature}`, {});

export const getConfig = (instance, config, defaultValue = {}) =>
  get(instance, `configurations.${config}`, defaultValue);
